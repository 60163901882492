import { CommonEnvironment } from './environment.common';

export class Environment extends CommonEnvironment {
  static contact: any = {
    email: 'nettredaksjonen@trondheim.kommune.no'
  };
  static content: any = {
    welcomeMessage:  {
      en: '',
      nb: ''
    },
    titleFrontpage: {
      en: 'Upcoming events',
      nb: 'Kommende arrangementer'
    },
    siteName: 'TK Events',
    siteNameTranslatable: {
      nb: 'TK Events',
      en: 'TK Events'
    }
  };
  static facebookAppID: string = '589387041568066';
  static firebase: any = {
    apiKey: "AIzaSyAmYTaWLSF9KVqc2_EspVf7RIv8CiCE00I",
    authDomain: "tk-events.firebaseapp.com",
    databaseURL: "https://tk-events.firebaseio.com",
    projectId: "tk-events",
    storageBucket: "tk-events.appspot.com",
    messagingSenderId: "71755497996",
    appId: "1:71755497996:web:4179073cbb917ee1bb6429",
    measurementId: "G-8NZRJ2CK1F"
  };
  static newsletterEnabled: boolean = false;
  static language: any = {
    multilanguage: false,
    defaultLanguage: 'nb'
  }
  static onlyForRegisteredUsers: boolean = true;
  static owner: any = {
    name: "Trondheim Kommune",
    link: 'https://www.trondheim.kommune.no/'
  };
  static production: boolean = true;
  static synchro: any[] = [
    {
      destination: "visitnorway",
      name: "Visit Norway",
      baseURL: "https://www.visitnorway.no/event/hsk/{slug}/",
      optional: true,
      checked: true,
      type: "visitnorway"
    },
    {
      destination: "trdevents-224613",
      name: "TRD Events",
      baseURL: "https://trdevents.no/event/{slug}/",
      optional: true,
      type: "hsk",
      checked: true,
    }
  ];
  static registration: any = {
    allowPublic: false
  };
  static siteURL: string = 'https://tkevents.no';
  static town: any = {
    name: 'Trondheim',
    hint: 'Trondheim Norway',
    lat: 63.430133,
    lng: 10.392836
  };
  static ui: any = {
    ... CommonEnvironment.ui,
    titleNewsletter: {
      en: 'Subscribe to events from Trondheim kommune',
      nb: 'Abonner på arrangement fra Trondheim kommune'
    },
    labelPrice: {
      nb: 'Pris',
      en: 'Price'
    },
    showAddEventForVisitors: false,
    showOnForm: {
      ... CommonEnvironment.ui.showOnForm,
      featuredEvent: true,
      maxCharsTitle: 100,
    },
    showOnFooter: {
      facebook: false,
      contact: true,
      organizers: false,
      privacy: true,
      what: false
    },
    showWelcomeMessage: false
  };
};

export const environment: any = Environment;
